
import { ScrapedArticle } from '@/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ScrapeResultCard extends Vue {
  @Prop({required: true}) result!: ScrapedArticle;

  select(){
    this.$emit('selectArticle', this.result)
  }
} 
